
.cart__order-full {
  padding: 0 0 40px;
  display: flex;
  gap: 20px;

  p {
    color: rgb(65, 65, 65);
  }

  .cart__order-full-right {
    .cart__order-full-nav {
      padding: 20px 0;
      border-bottom: 1px solid rgba(65, 65, 65, 0.2);

      .cart__order-full-nav-text {
        font-size: 20px ;

      }
    }


  }

  .cart__order-full-list1 {
    cursor: pointer;
    display: flex;
    padding: 15px 0;
  }


  .cart__order-full-list1-open {
    input {
      border: 1px solid rgba(0, 0, 0, 0.38);
      padding: 10px;
    }

    button {
      border: 1px solid rgba(0, 0, 0, 0.38);
      padding: 10px 20px;
      cursor: pointer;
    }
  }



}
@media (max-width: 1032px) {
  .cart__order-full {
    flex-direction: column;
    .cart__order-full-left {
      width: 100%;
    }
  }
}

  @media (max-width: 816px) {
    .cart__order-full {
      width: 693px;
      flex-direction: column;
      .cart__order-full-left {
        width: 100%;

        .cart__order-full-nav {
          display: flex;
          justify-content: center;
        }
      .card__order-full-btn{
        .card__order-security{
          .card__order-full-btn-security{
            p{
              font-size: 12px;
            }
          }
        }
      }
      }


      .cart__order-full-right {
        .quantity {
          gap: 18px;
        }

        .cart__order-full-nav {

          .cart__order-full-nav-text {
            display: flex;
            justify-content: center;

          }
        }
      }

      .cart__order-full-list {
        margin-left: 20px;
      }
    }
  }
@media (max-width: 701px) {
  .cart__order-full {
    width: 320px;
    flex-direction: column;
    padding: 0;
    gap: 0;
    .cart__order-full-left {
      width: 100%;

      .cart__order-full-nav {
        display: flex;
        justify-content: center;
      }
    }
    p{
      font-size: 11px;
    }


    .cart__order-full-right {
      .quantity {
        gap: 0;
      }

      .cart__order-full-nav {

        .cart__order-full-nav-text {
          display: flex;
          justify-content: center;

        }
      }
    }

    .cart__order-full-list {
      margin-left: 20px;
    }
  }
}

