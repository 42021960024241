
.mid {

  .mid_background2 {
    background-position: center;
    display: grid;
    justify-content: center;

    .one {

    }

    .two {
    }
  }
}