.error_404-wrapper{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(60.97% 60.97% at 50% 50%, #423F3F 0%, #443E3E 62.27%, #3E3C3C 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  opacity: 1;
  transition: opacity 0.6s ease-out;
  .error_404-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    .error_404-title-wrapper{
      display: flex;
      flex-direction: column;
      align-items: center;

    .error_404-title{
      font-size: 300px;
      color: white;
      font-weight: 600;
    }
    .error_404-text{
      color: white;
      font-size: 20px;
      font-weight: 400;
      text-transform: capitalize;
    }
  }
    .error_404-button{
      margin-top: 20px;
      .error_404-button-wrapper{
        display: flex;
        align-items: center;
        gap: 10px;
        color: white;
        font-size: 18px;
        font-weight: 400;
        text-transform: capitalize;
        border: 1px solid white;
        padding: 10px 15px;
        border-radius: 5px;
        transition: all 0.3s ease-in-out;
        &:hover{
          color: #000000;
          transition: all 0.3s ease-in-out;
          cursor: pointer;
          background: #545454;
          transform: scale(1.1);
          border: 1px solid white;
          .a{
            color: #000000;
          }
        }

      a{
        text-decoration: none;
        color: white;
        font-size: 18px;
        font-weight: 400;
        text-transform: capitalize;
        &:hover{

        }
      }
      img{
        max-width: 50px;
        height: auto;
        cursor: pointer;

        transition: all 0.3s ease-in-out;
        &:hover{
          transform: scale(1.1);
          transition: all 0.3s ease-in-out;
          cursor: pointer;
        }
      }
    }
    }
  }
}
@media screen and (max-width: 768px) {
  .error_404-wrapper{
    .error_404-content{
      gap: 30px;
      .error_404-title-wrapper{
        gap: 5px;
      .error_404-title{
        font-size: 200px;
      }
      .error_404-text{
        font-size: 16px;
      }
      }
      .error_404-button{
        .error_404-button-wrapper{
          gap: 5px;
        }
        img{
          max-width: 30px;
        }
        .a{
          font-size: 16px;
        }
      }
    }
  }
}
@media screen and (max-width: 350px) {
  .error_404-wrapper{
    .error_404-content{
      gap: 20px;
      .error_404-title-wrapper{
        gap: 3px;
        .error_404-title{
          font-size: 150px;
        }
        .error_404-text{
          font-size: 14px;
        }
      }
      .error_404-button{
        .error_404-button-wrapper{
          gap: 3px;
        }
        img{
          max-width: 25px;
        }
        .a{
          font-size: 16px;
        }
      }
    }
  }
}