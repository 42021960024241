.productPage__left-page {
  margin-right: 15px;
  .productPage__left-page-img-container {
    position: relative;
    transition: filter 0.3s ease-out;

    .product_image {
      object-fit: contain;
    }
    &:hover {
      filter: grayscale(0%);
    }

    .images_zoom {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      justify-content: flex-end;
      cursor: pointer;

      .images_zoom_background {
        cursor: pointer;
        border-radius: 50%;
        width: 50px; /* Adjust as needed */
        height: 50px; /* Adjust as needed */
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background-color 0.5s;
        img {
          width: 25px;
          height: 25px;
        }
      }
      .images_zoom_background:hover {
        background-color: rgba(220, 220, 220, 0.83);
      }
    }

    .productPage__left-page-img {
      width: 500px;
      height: 500px;
    }

    justify-content: center;
  }

  .productPage__left-text {
    margin-top: 20px;

    p {
      font: normal normal normal 14px/17px "courier new", courier-ps-w01, courier-ps-w02, courier-ps-w10, monospace;
    }
  }
  .circle {
    display: flex;
    gap: 10px;
    justify-content: center;
    position: relative;
    width: calc(5 * calc(4 * 4px));
    overflow: hidden;
    margin: 0 auto;

    input {
      margin-top: 30px;
      color: black;
    }

    .input_circle {
      width: 50px;
      height: 50px;
      cursor: pointer;
    }

  }
}
@media (max-width: 816px) {
  .productPage__left-page {
    margin-right: 0;
    .productPage__left-page-img-container{
      .images_zoom{
        .images_zoom_background{
          display: none;
          img {
            width: 15px;
            height: 15px;
          }
        }
      }
    .productPage__left-page-img {
      width: 271px;
      height: 273px;
    }

    .productPage__left-text {
      display: none;
    }
  }
  }
}