.mid {
  .mid_background1 {
    background-repeat: no-repeat;
    background-position: center;
    display: grid;
    left: 33px;
    justify-content: center;

    // background-image: url("../assets/images/backgr.jpg");
    background-image: url("../assets/images/background_image-test.jpg");
    // background-image: url("../assets/images/fon (2).png");
    
    @media screen and (max-width: 768px) {
      background-image: url("../assets/images/background_image-test.jpg");
      
    }
    .one {
      .header_back {
        display: flex;
        align-items: center;
        gap: 5px;
        .BACK {
          font-size: 18px;
          font-weight: 700;
        }
      }
    }
  }
}
