
.checkout {
  display: flex;
  gap: 20px;
  padding-top: 60px;

}

@media (max-width: 600px) {
  .checkout {
    flex-direction: column;
    padding: 40px;

    .checkout-left {
      width: 100%;
    }

    .checkout-right {
      .checkout-right__order {
        width: 100%;
        padding: 10px;

        .cart__order-full-delivery {
          .cart__order-full-price {
            font-size: 0.8rem;
          }
        }
.card__order-full-btn{
  .card__order-full-btn-text{
    p{
      font-size: 0.8rem;
    }

  }
}
        .checkout-right__order-header {
          font-size: 0.8rem;

          .checkout-right__order-header-data {
            font-size: 0.8rem;
          }
        }
      }

    }
  }
}
