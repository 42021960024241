.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  opacity: 1;
  transition: opacity 0.6s ease-out;
}

.preloader-images img {
  width: 500px;
  height: 500px;
  animation: pulse 1s infinite;
}

.loading {
  opacity: 0;
  pointer-events: none;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 1200px) {
  .preloader-images img{
    width: 500px;
    height: 500px;
  }
}
@media (max-width: 600px) {
  .preloader-images img{
    width: 320px;
    height: 320px;
  }
}
@media (max-width: 320px) {
  .preloader-images img{
    width: 270px;
    height: 270px;
  }
}