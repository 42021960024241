.unsuccessful-order {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(60.97% 60.97% at 50% 50%, #423F3F 0%, #443E3E 62.27%, #3E3C3C 100%);
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  opacity: 1;
  transition: opacity 0.6s ease-out;

  .unsuccessful-order-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 74px;

    .unsuccessful-order-title {
      color: white;
      text-align: center;
      font-size: 150px;
      font-style: normal;
      font-weight: 400;
      line-height: 80.719%;
    }

    .unsuccessful-order-title-down {
      color: #b60909;
      font-size: 150px;
      font-style: normal;
      font-weight: 400;
      line-height: 80.719%;
    }
    .unsuccessful-order-button{
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
      .unsuccessful-order-button-item{
        border: 1px solid #CCB0B0;
        display: flex;
        align-items: center;
        gap: 5px;
        border-radius: 5px;
        background-color: transparent;
        padding: 20px 30px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        &:hover{
          background-color: #CCB0B0;
          transform: scale(1.1);
          .unsuccessful-order-button-text{
            color: #000000;
          }
          img{
            filter: invert(100%);
          }
        }
        img{
          width: 25px;
          height: 25px;
          transition: all 0.3s ease-in-out;
        }
        .unsuccessful-order-button-text{
          color: #CCB0B0;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 22px;
          text-align: center;
          transition: all 0.3s ease-in-out;
          text-transform: uppercase;
          text-decoration: none;
          letter-spacing: 1.5px;
        }
      }
    }
  }
}
@media  (max-width: 768px) {

  .unsuccessful-order{
    .unsuccessful-order-content{
      .unsuccessful-order-title{
        font-size: 65px;
      }
      .unsuccessful-order-title-down{
        font-size: 65px;
      }
    }
    .unsuccessful-order-button{
      .unsuccessful-order-button-item{
        .unsuccessful-order-button-text{
          font-size: 16px;
        img{
          width: 20px;
          height: 20px;
        }
        padding: 0 10px;
        gap: 5px;
        border-radius: 5px;
        .unsuccessful-order-button-text{
          font-weight: 700;
          line-height: 22px;
          letter-spacing: 1.5px;
          font-size: 16px;

        }
      }
      }
    }
  }

}