.container {
  display: grid;

  .header__clothes {
    left: 33px;
    grid-area: 4 / 1 / 5 / 2;
    justify-self: start;
    align-self: start;
    padding: 20px;
  }
}

.header__clothes-card {
  display: grid;
  grid-gap: 14px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 1;
  grid-row-gap: 0;

  .card {
    .card-button-container {
      position: relative;
      bottom: 79px;
      cursor: pointer;
      width: 100%;
      opacity: 0;
      transform-origin: bottom center;
      transition:
        opacity 0.3s ease-in-out,
        transform 0.3s ease-out;

      .card-button {
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.87);
        color: #000000;
        border: none;
        width: 100%;
        padding: 16px 16px;
      }
    }

    /* Add animation for button container on hover */
    &:hover {
      .card-button-container {
        opacity: 1;
        transform: translateY(0) scaleY(1);
      }

      .header__clothes-card-img {
        &.hovered {
          transform: scale(1.1);
        }
      }
    }
  }
  .price_text {
    font-weight: 600;
    font-size: 16px;
    color: black;
    text-decoration: none;
  }

  .header__clothes-card-img {
    width: 310px;
    height: 310px;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;

    &.hovered {
      transform: scale(1.1);
    }
  }

  .card1 {
    grid-area: 1 / 1 / 2 / 2;
    text-align: left;
  }

  .card2 {
    grid-area: 1 / 2 / 2 / 3;
    text-align: left;
  }

  .card3 {
    grid-area: 1 / 3 / 2 / 4;
    text-align: left;
  }

  .card4 {
    grid-area: 2 / 1 / 3 / 2;
    text-align: left;
  }

  .card5 {
    grid-area: 2 / 2 / 3 / 3;
    text-align: left;
  }

  .card6 {
    grid-area: 2 / 3 / 3 / 4;
    text-align: left;
  }

  .card7 {
    grid-area: 3 / 1 / 4 / 2;
    text-align: left;
  }

  .card8 {
    grid-area: 3 / 2 / 4 / 3;
    text-align: left;
  }

  .card9 {
    grid-area: 3 / 3 / 4 / 4;
    text-align: left;
  }
}
@media screen and (max-width: 816px) {
  .header__clothes-card .card .card-button-container .card-button {
    display: none;
  }
}
