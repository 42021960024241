* {
	font-family: "Kelly Slab", sans-serif;
	background: transparent;
	border: 0;
	margin: 0;
	outline: 0;
	padding: 0;
	vertical-align: baseline;
	box-sizing: border-box;
	outline: none;
}
mt-5 {
	margin-top: 5px;
}

.inpt {
	padding: 10px 10px 10px;
	width: 100%;
	background-color: #ffffff;
	border: 1px solid #000000;
}

body {
	overflow-x: hidden;
	overflow-y: auto;
	&::-webkit-scrollbar {
		width: 23px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgb(65, 65, 65);
		border: 1px solid white;
	}

	&::-webkit-scrollbar-track {
		background-color: rgba(243, 243, 243, 0.42);
	}
}
ul {
	list-style: none;
}
a {
	text-decoration: none;
}

//@mixin noselect {
//  -webkit-touch-callout: none; /* iOS Safari */
//  -webkit-user-select: none; /* Safari */
//  -khtml-user-select: none; /* Konqueror HTML */
//  -moz-user-select: none; /* Old versions of Firefox */
//  -ms-user-select: none; /* Internet Explorer/Edge */
//  user-select: none; /* Non-prefixed version, currently
//                                  supported by Chrome, Opera and Firefox */
//}
