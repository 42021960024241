.window {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  .drawer1 {
    background-color: white;
    width: 100%;
    max-height: 90vh;
    max-width: 1000px;
    gap: 30px;
    overflow-y: auto;
    padding: 32px;
    overflow: hidden;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    .cross-container {
      display: flex;
      justify-content: flex-end;
      cursor: pointer;

      .custom-input-container {
        position: relative;

        .custom-input-container-main {
          display: flex;
          align-items: center;
          padding: 5px;
          border: 3px solid rgba(190, 190, 190, 0.13);

          .img_quantity1 {
            width: 12px;
            height: 15px
          }

          .img_quantity {
            width: 10px;
            height: 10px;
          }

          .text_quantity {
            padding-right: 10px;
            padding-left: 10px;
          }
        }

        .inp {
          padding: 5px;
        }
      }

      .arrow-icon {
        position: absolute;
        top: 50%;
        right: 8px; /* Adjust the right spacing as needed */
        transform: translateY(-50%);
        width: 16px; /* Adjust the width as needed */
        height: 16px; /* Adjust the height as needed */
        cursor: pointer; /* Optional: Add a pointer cursor */
      }

    }

    .windowAll {
      display: flex;
      gap: 10px;

      .window__left {
        display: flex;
        flex-direction: column;
        align-items: center;

        .image-indicators {
          display: flex;
          justify-content: center;
          margin-top: 10px;

          .image-indicator {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            cursor: pointer;
            background-color: #9a9292;
            margin-right: 5px;

            &:hover {
              background-color: #000000;
            }

            &.active {
              background-color: #000000;
            }
          }
        }
      }
    }

    .window__left-img {
      width: 500px;
      height: 500px;
      margin-right: 20px;
      margin-bottom: 20px;
      justify-content: center;
      border: 6px solid rgba(253, 253, 253, 0.9);
      border-radius: 8px;
      background-color: rgba(236, 236, 236, 0.7);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      transition: background-color 0.6s; /* Add a smooth transition for background color */
    }

    .window__left-img:hover {
      background-color: transparent; /* Make the background fully transparent on hover */
    }

    /* Add a shining effect on the border on hover */
    .window__left-img:hover {
      animation: shine 1s forwards;
    }

    @keyframes shine {
      to {
        border-color: rgb(218, 218, 218);
      }
    }

    .circle {
      display: flex;
      gap: 10px;
      justify-content: center;
      position: relative;
      width: calc(5 * calc(4 * 4px));
      overflow: hidden;
      margin: 0 auto;

      input {
        color: black;
        margin-top: 30px;
      }
    }
  }
}


.window__right {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 274px;

  .black_li {
    color: black;
    text-decoration: underline;
    transition: color 0.3s;

    &:hover {
      color: #5d5d5d;
    }
  }

  .window__right-text {
    font-size: 16px;
    line-height: 15px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-transform: uppercase;

    p {
      word-break: break-all;
    }
  }

  .window__right-size {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .select{
      border-radius: 0;
      height: 40px;
      cursor: pointer;
      border: 1px solid #858585;
    }

    .select_sizeWindow {
      color: rgba(255, 0, 0, 0.55);
      font-weight: 600;
      font-size: 16px;
    }

    p {
      text-transform: uppercase;

    }

    .window__right-size-btn {
      display: flex;
      border: 1px solid black;
      padding: 10px;
      cursor: pointer;
      align-items: center; /* Align items vertically in the center */

      p {
        flex: 1; /* Take up remaining space */
      }

      img {
        margin-left: 10px; /* Adjust the margin as needed */
        max-width: 100px; /* Set a maximum width for the image */
        height: auto; /* Maintain the aspect ratio of the image */
      }
    }

    .window__right-size-open {
      object-fit: contain;
      object-position: center center;
      z-index: 1;
      position: absolute;
      margin: 66px 0;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      width: 274px;

      li {
        background-color: #f5f5f5;
        list-style: none;
        padding: 5px 5px;
        border: 1px solid rgba(161, 161, 161, 0.53);

        &:hover {
          color: white;
          background-color: #797979;
        }
      }
    }
  }



  .window__right-number {
    input {
      border: 1px solid black;
    }
  }

  .window__right-btn {
    border: 1px solid black;
    padding: 15px 80px;
    transition: border 0.3s;

    p {
      color: black;
      transition: color 0.3s;
    }

    &:hover {
      border: 1px solid #999997;

      p {
        color: #999997;
      }
    }
  }
}


@media (max-width: 888px) {
  .window {
    display: none;
  }
}


.original_price {
  text-decoration: line-through;
  opacity: 90%;
  margin-right: 7px;
  color: gray;
}