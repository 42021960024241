.cart__order-full-left {
  width: 390px;
  padding: 20px;

  .cart__order-full-nav {
    padding-bottom: 20px;

    border-bottom: 1px solid rgba(65, 65, 65, 0.2);

    .cart__order-full-nav-text {
      font-size: 20px ;
    }
  }

  .cart__order-full-delivery {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-bottom: 1px solid rgba(65, 65, 65, 0.2);

    .cart__order-full-price {
      display: flex;
      justify-content: space-between;
    }
  }

  .card__order-full-btn {
    padding-top: 20px;

    .card__order-full-btn-text {
      display: flex;
      justify-content: space-between;

      p {
        font-size: 20px;
      }
    }

    button {
      cursor: pointer;
      margin: 30px 30px 0 30px;
      border: 1px solid black;
      padding: 10px 90px;
    }
    .card__order-security{
      display: flex;
      flex-direction: column;
      align-items: center;
      .card__order-full-btn-security {
        display: flex;
        gap: 5px;
        margin: 10px 80px 0;
        align-items: center;

        p {
          font-family: Helvetica, sans-serif;
          font-size: 14px;
        }
      }
    }

  }
}
@media (max-width: 816px) {
   .cart__order-full-left {

   }
}