.ContactsComponent {
  padding-bottom: 130px;
  margin: 0 auto;

  p {
    font-size: normal normal normal 28px/1.4em proxima-n-w01-reg,proxima-n-w05-reg,sans-serif;
    line-height: 1.3em;
    margin-bottom: 50px;
    width: 1000px;
    a{
      margin-left: 3px;
    }
  }
}
@media screen and (max-width: 816px) {
  .ContactsComponent {
    padding: 20px;
   p{
  width: 300px;
    }
   /* Adjust the width for smaller screens */
  }
}

@media screen and (max-width: 320px) {
  .DeliveryComponent {
    padding: 20px;
    p {
      width: 254px;
  } 
  }
}
@media screen and (max-width: 300px) {
.ContactsComponent p {
  width: 252px;
}
}
