

.checkout_main {
  width: 100%;
  border-bottom: 1px solid #999997;
  .header__checkout {
    div {
      display: flex;
      justify-content: space-between;
      padding: 20px 0;

      flex: 1;

      h1 {
        color: black;
        font-size: 20px;
      }
      p {
        color: black;
        font-size: 15px;
      }
    }
  }
}

  @media (max-width: 928px) {
    .checkout_main {
    .header__checkout {
      div {
        display: flex;
        justify-content: center;
        p {
          font-weight: bold;
          text-transform: uppercase;
        }
        h1{
          display: none;
        }
      }
    }
  }
}