.main_quantity{
  .quantity-card{
    border: 1px solid #a2a2a2;
    border-radius: 0;
    width: 100%;
    margin-top: 10px;
  }
  .quantity-card:hover{
    color: #919191;
  }

}