.switch_cardfull {
  display: flex;
  margin-top: 5px;
  gap: 3px;
  .input_circle {
    cursor: pointer;
    border: 2px solid rgba(133, 133, 133, 0.37);
    background-color: rgba(255, 255, 255, 0.46);
    transition: background-color 0.3s ease, border 0.4s ease;
  }

  .input_circle:hover,
  .input_circle:active {
    background-color: rgba(255, 255, 255, 0.8);
    border: 2px solid rgba(194, 194, 194, 0.52);
  }
  .input_circle {
    cursor: pointer;
    border: 2px solid rgba(133, 133, 133, 0.37);
    background-color: rgba(255, 255, 255, 0.46);
    transition: background-color 0.3s ease;
  }

  .input_circle:hover {
    background-color: rgba(255, 255, 255, 0.8); /* Change the background color on hover */
  }
}

@media (max-width: 816px) {
  .switch_cardfull {
    display: flex;
    justify-content: center;
  }
}