.card {

.price_text{
  font-weight: 600;
  font-size: 16px;
  color: black;
  text-decoration: none;
}

.header__clothes-card-img {
  width: 310px;
  height: 310px;
  object-fit: contain;
  transition: transform 0.3s ease-in-out;

  &.hovered {
    transform: scale(1.1);
  }
}
}



.card1 {
  grid-area: 1 / 1 / 2 / 2;

}

.card2 {
  grid-area: 1 / 2 / 2 / 3;

}

.card3 {
  grid-area: 1 / 3 / 2 / 4;

}

.card4 {
  grid-area: 2 / 1 / 3 / 2;

}

.card5 {
  grid-area: 2 / 2 / 3 / 3;

}

.card6 {
  grid-area: 2 / 3 / 3 / 4;

}

.card7 {
  grid-area: 3 / 1 / 4 / 2;

}

.card8 {
  grid-area: 3 / 2 / 4 / 3;

}

.card9 {
  grid-area: 3 / 3 / 4 / 4;
}
@keyframes scrollAnimationCard {
  0% {
    opacity: 0;
    filter: blur(5px);

  }
  100% {
    opacity: 1;
    filter: blur(0);
  }
}
@media (max-width: 1200px) {
  .price_text{
    font-weight: 400;
    font-size: 12px;
  }
}
@media  (max-width: 1200px) {
  .header__clothes-card{
    display: flex;
    gap: 14px;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    .header__clothes-card-img {
      width: 260px;
      height: 245px;
    }
  }
}
@media  (max-width: 521px) {
  .header__clothes-card{
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
    align-content: center;
    justify-content: center;
    .header__clothes-card-img {
      width: 170px;
      height: 160px;
    }
  }
}
@media  (max-width: 521px) {
  .header__clothes-card{
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
    align-content: center;
    justify-content: center;
    .header__clothes-card-img {
      width: 155px;
      height: 145px;
    }
  }
  .card {
    .card-button-container{
    }
    .card-button-container {
      .card-button {
        padding: 6px 6px;
      }
    }
  }
}
@media  (max-width: 342px) {
  .header__clothes-card{
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    .header__clothes-card-img {
      width: 140px;
      height: 130px;
    }
  }
  .card {
    .card-button-container{
    }
    .card-button-container {
      .card-button {
        padding: 6px 6px;
      }
    }
  }
}

@media  (max-width: 319px) {
  .header__clothes-card{
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    .header__clothes-card-img {
      width: 100px;
      height: 90px;
    }
  }
  .card {
    .card-button-container{
    }
    .card-button-container {
      .card-button {
        padding: 6px 6px;
      }
    }
  }
}
