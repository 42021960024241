.header {
  .containerHeader {
    display: flex;
    justify-content: space-between;
    .header__main-logo{
      display: flex;
      gap: 10px;

      .header__main-logo-img{
        width: 60px;
        height: 60px;
        margin-top: 10px;
        margin-left: 5px;
        cursor: pointer;
        transition: all 0.4s ease-in-out;
        &:hover{
          transform: scale(1.1);
          cursor: pointer;
          filter: brightness(1.1);
        }

      }
      .header__main-logo-fors{
        width: 60px;
        height: 60px;
        margin-top: 10px;
        margin-right: 10px;
        cursor: pointer;
        transition: all 0.4s ease-in-out;
        &:hover{
          transform: scale(1.1);
          cursor: pointer;
          filter: brightness(1.1);
        }

      }
    }
    .header__product {
      display: flex;
      color: #414141;
      margin-top: 35px;
      margin-bottom: 60px;

      .header__product-nav {
        flex: 1 1 0;

        .header__product-navigation {
          display: flex;
          gap: 10px;

          .header__product-navigation-bold {
            font-weight: bold;
            color: black;
            font-size: 22px;
          }

          .header__product-navigation-opacity {
            opacity: 0.9;
          }

          p {
            font: normal normal normal 14px/17px "courier new", courier-ps-w01, courier-ps-w02, courier-ps-w10, monospace;
          }
        }
      }

      .header__product-navigation2 {
        display: flex;
        align-items: center;

        p {
          font: normal normal normal 14px/17px "courier new", courier-ps-w01, courier-ps-w02, courier-ps-w10, monospace;
        }
      }
    }
  }
}

@media (max-width: 816px) {
  .header{
    .containerHeader {
      margin: 0 10px;
      .header__main-logo{
        .header__main-logo-img{
          width: 40px;
          height: 40px;
          margin-top: 40px;
          margin-left: 34px;
        }
        .header__main-logo-fors{
          width: 45px;
          height: 45px;
          margin-top: 15zpx;
        }
      }
      .header__product{
        padding-left: 21px;
        gap: 20px;
        margin-bottom: 45px;
        .header__product-nav {
          .header__product-navigation{
            .header__product-navigation-bold{
              font-size: 1rem;
            }
            p{
              font: normal normal normal 14px/17px "courier new", courier-ps-w01, courier-ps-w02, courier-ps-w10, monospace;
              word-break: break-all;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 320px) {
  .header{
    .containerHeader {
      margin: 0 10px;
      .header__main-logo{
        .header__main-logo-img{
          width: 50px;
          height: 50px;
          margin-top: 40px;
          margin-left: 19px;
        }
        .header__main-logo-fors{
          width: 35px;
          height: 35px;
        }
      }
      .header__product{
        padding-left: 21px;
        gap: 20px;
        margin-bottom: 45px;
        .header__product-nav {
          .header__product-navigation{
            .header__product-navigation-bold{
              font-size: 1rem;
            }
            p{
              font: normal normal normal 14px/17px "courier new", courier-ps-w01, courier-ps-w02, courier-ps-w10, monospace;
              word-break: break-all;
            }
          }
        }
      }
    }
  }
}