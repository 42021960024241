.card__full {
  .main-see_sizes-images {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    opacity: 1;
    gap: 30px;
    transition: opacity 0.6s ease-out;
    .see_sizes-images {
      margin-bottom: 10px;
      margin-top: 10px;
      display: flex;
      justify-content: center;

      img {
        border: 4px solid rgba(136, 136, 136, 0.51);
        border-radius: 9px;
        width: 600px;
        height: 600px;
      }
    }
  }
  .card__full-all {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 1024px) {
  .card__full {
    .main-see_sizes-images {
      .see_sizes-images {
        img {
          width: 400px;
          height: 400px;
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .card__full {
    .main-see_sizes-images {
      .see_sizes-images {
        img {
          width: 500px;
          height: 500px;
        }
      }
    }
  }
}

@media (max-width: 816px) {
  .card__full {
    .main-see_sizes-images {
      .see_sizes-images {
        img {
          width: 300px;
          height: 300px;
        }
      }
    }
    .card__full-all {
      flex-direction: column;
      align-items: center;

      .window__right {
        gap: 10px;

        .window__right-btn {
          margin-bottom: 20px;
        }

        .window__right-size {
          gap: 0;

          .window__right-size-open {
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}
