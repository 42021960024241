.PrivacyPolicyComponent {
  padding-bottom: 130px;
  margin: 0 auto;

  p {
    font-size: normal normal normal 28px/1.4em proxima-n-w01-reg, proxima-n-w05-reg, sans-serif;
    line-height: 1.3em;
    margin-bottom: 50px; /* Adjust the value to your preference */
    width: 1000px;
  }
}

@media screen and (max-width: 816px) {
  .PrivacyPolicyComponent {
    padding: 20px;
  }

  .PrivacyPolicyComponent p {
    font-size: 11px;
    width: 300px;
  }
}


@media screen and (max-width: 300px) {
  .PrivacyPolicyComponent {
    padding: 20px;
  }

  .PrivacyPolicyComponent p {
    font-size: 11px;
    width: 254px;
  }
}