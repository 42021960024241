.mid{
  .mid_background1 {
    //background-repeat: no-repeat;
    //background-image: url("https://static.wixstatic.com/media/4e6b23_d8f50e04df0b46648330bff45003d711~mv2.jpg/v1/fill/w_1805,h_2120,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/4e6b23_d8f50e04df0b46648330bff45003d711~mv2.jpg");
    //background-size: cover;
    //background-position: center;
    background-color: #FFFFFF;
    display: grid;
    left: 33px;
    justify-content: center;


    .one1 {
    }

    .two {

    }
  }
}
