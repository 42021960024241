.mid1 {
  margin: 0 auto;
  width: 100%;
  max-width: 890px;
}


@media (max-width: 928px) {
  .mid1 {
    max-width: 900px;
  }
}