.cartItems {
  width: 600px;
  border-bottom: 1px solid rgba(65, 65, 65, 0.2);
  padding: 32px 0;

  .cartItems-left {
    display: flex;
    align-items: flex-start;

    .quantity1 {
      display: flex;
    }

    .cartItems-img {
      margin-right: 20px;
      background-color: rgba(91, 90, 90, 0.18);
      display: flex;
      align-items: center;
      border: 1px solid #858585;
      justify-content: center;

      img {
        position: relative;
      }
    }
  }

  .cart__order-full-right-text {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 5px;

    .text {
      margin-bottom: 2px;
      display: block;
      max-width: 100%;
      word-break: break-all;
      font-size: 16px;
      line-height: 13px;
      text-transform: uppercase;
    }

    .cart__order-full-right-price {
      font-size: 16px;
      line-height: 20px;

      b {
        display: flex;
        gap: 5px;
      }
    }
  }
}


@media (max-width: 780px) {
  .cartItems {
    width: 609px;
    border-bottom: none;

    padding: 3px;

    .cartItems-left {
      margin: 30px;

      .cart__order-full-right-text {
        display: flex;
        .text {
           font-size: 10px;
        }
        .cart__order-full-right-price {
          font-size: 12px;
        }
      }

      .cartItems-img {
        img {
          width: 75px;
          height: 75px;
        }
      }
    }
  }
}

@media (max-width: 701px) {
  .cartItems {
    width: 348px;
    border-bottom: none;
    padding: 0;


    .cartItems-left {
      margin: 30px;

    

      .cart__order-full-right-text {
        display: flex;

        .cart__order-full-right-price {
          font-size: 12px;
        }
      }

      .cartItems-img {
        img {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}
@media (max-width: 320px) {
  .cartItems {
    width: 295px;
    flex-direction: column;
    padding: 0;
    gap: 0;


    .cartItems-left {
      margin: 30px;



      .cart__order-full-right-text {
        display: flex;

        .cart__order-full-right-price {
          font-size: 12px;
        }
      }

      .cartItems-img {
        img {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}