.logo_main {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  img {
    height: 320px;
  }
  margin: 30px 0 40px 0;
}

@media (max-width: 616px) {
  .logo_main {
    img {
      width: 420px;
      height: 300px;
    }
    margin: 20px 0;
  }
}

@media (max-width: 460px) {
  .logo_main {
    img {
      height: 130px;
      width: 250px;
    }
    margin: 10px 50px 10px 50px;
  }
}

@media (max-width: 320px) {
  .logo_main {
    img{
      height: 150px;
      width: 250px;
    }
  }
}

@media (max-width: 280px) {
  .logo_main {
    img{
      height: 120px;
      width: 200px;
    }
    margin: 0 10px ;
  }
}