.product__page-size {
  cursor: pointer;
  .select{
    height: 40px;
    margin-top: 10px;
    width: 100%;
    border-radius: 0;
    cursor: pointer;
    margin-bottom: 7px;
    border: 1px solid #858585;
  }
  .select_size {
    font-size: 16px;
    font-weight: 600;
  }

  p {
    text-transform: uppercase;

  }

  .product__page-size-btn {
    display: flex;
    background-color: #FFFFFF;
    border: 1px solid black;

    cursor: pointer;
    align-items: center;
    p {
      flex: 1; /* Take up remaining space */
    }
    img {
      margin-left: 10px; /* Adjust the margin as needed */
      max-width: 100px; /* Set a maximum width for the image */
      height: auto; /* Maintain the aspect ratio of the image */
    }
  }
}

