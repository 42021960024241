.cart__order-full-right {
  .quantity {
    gap: 18px;
  }

  .cart__order-full-nav {

    .cart__order-full-nav-text {
      display: flex;

    }
  }
}

@media (max-width: 816px) {
  .cart__order-full-right{
.cart__order-full-list{
  display: none;
}
  }
}