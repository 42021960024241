.checkout-right__order-promo-main {
  flex-direction: column;
  padding: 20px 0;
  .checkout-right__order-promo-text {
    display: flex;
    cursor: pointer;
    gap: 5px;
  }
  .checkout-right__order-promo-open {
    display: flex;
    flex-direction: column;
    align-content: center;
    gap: 8px;
    margin-top: 10px;
    justify-content: flex-start;
    align-items: center;
    input {
      background-color: #ffffff;
      border: 1px solid black;
      width: 100%;
      padding: 15px;
    }

    button {
      display: flex;
      width: 100%;
      gap: 3px;
      background-color: rgb(65, 65, 65);
      align-items: center;
      border: none;
      height: 35px;
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      transition: background 0.1s ease-in-out;
      justify-content: center;

      &:hover {
        background: lighten(rgba(65, 65, 65, 1), 5%);
      }

      &:active {
        background: darken(rgba(65, 65, 65, 1), 5%);
      }
      p {
        color: #ffffff;
      }
    }
  }
}

@media (max-width: 430px) {
  .checkout-right__order-promo-main {
    font-size: 0.8rem;
    .checkout-right__order-promo-open {
      input {
        padding: 8px;
      }
      button {
        height: 28px;
      }
    }
  }
}
