.see_sizes{
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 15px;


  .see_sizes-btn{
    display: flex;
    background-color: #FFFFFF;
    border: 1px solid black;
    padding: 5px;
    width: 100%;
    cursor: pointer;
    align-content: stretch;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .see_sizes-text{
      font-weight: 500;
      font-size: 22px;
      cursor: pointer;
      padding: 10px 6px;
    }

    .see_sizes-img{
      margin-left: 10px;
      max-width: 180px;
      width: 30px;
      height: 30px;
      align-items: flex-end;
      display: flex;
    }
  }
}


@media (max-width: 816px) {
  .see_sizes{
    justify-content: center;
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 15px;
    .see_sizes-btn{
      padding: 5px;
      width: 296px;
      .see_sizes-text{
        font-size: 15px;
      }
      .see_sizes-img{
        width: 20px;
        height: 20px;
      }
    }
  }
}
@media (max-width: 325px) {
  .see_sizes{
    justify-content: center;
    align-content: center;
    align-items: center;
    .see_sizes-btn{
      padding: 5px;
      width: 245px;
      flex-wrap: nowrap;
      .see_sizes-text{
        font-size: 13px;
      }
    }
  }
}