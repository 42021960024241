.footer {
  display: grid;
  margin: 0 auto;
  max-width: 1000px;
  padding: 30px 0 20px;

  .footer-all {
    display: flex;
    flex-direction: column;
    gap: 23px;


    .footer-top {
      display: flex;
      justify-content: space-between;

      ul {
        cursor: pointer;
        li{
          color: black;
          transition: color 0.4s;
        }
        li:hover{
          color: #999997;
        }
      }
      .icons{
        display: flex;
        gap: 5px;
        cursor: pointer;
        .icon{
          color: #4c4a4a;
        }
      }

      .activeFooter {
        color: #999997;

        :hover {
          color: #cbcbcb;
        }
      }

      .footer__img {
        display: flex;
        gap: 5px;
        .footer__img-link{
          color: #4c4a4a;
          img{
            transition: all 0.4s;
          }
          img:hover{
              transform: scale(1.1);
              filter: drop-shadow(0 0 10px #e1e1e1);
          }
        }
      }
    }
  }
}

@media(max-width: 1200px) {
  .footer {
    padding: 30px 30px 20px;
    .footer-all {

      .footer-top {

        justify-content: space-between;
        gap: 20px;
        .footer__img-text{
          display: none;
        }
      }
    }
  }
}