.header {
  .header__main {
    display: flex;
    gap: 25px;
    justify-content: space-between;
    position: relative;
    margin: 6px 8px 39px calc((100% - 980px) * 0.5);

    .header__main-cart {
      display: flex;
      gap: 3px;
      align-items: center;
      margin-top: 10px;

      .shopping-cart {
        width: 40px;
        height: 38px;
      }

      p {
        font-weight: 200;
        font-size: 15px;
      }
    }

    .header__main-btn {
      background: #fff0f0;
      cursor: pointer;
      padding: 8px 60px;
      display: flex;
      align-items: center;
      border: 1px solid #000000;
    }

    .popup {
      position: absolute;
      background: #ffffff;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.09);
      overflow: hidden;
      cursor: pointer;
      padding: 0 60px;
      display: flex;
      width: 177px;
      align-items: center;

      ul {
        overflow: hidden;

        li {
          padding: 12px 5px;

          .active {
            color: #000000;

            :hover {
              background-color: #414141;
            }
          }

          .active {
            background: rgba(30, 254, 79, 0.11);
          }
        }
      }
    }
  }
}

@media (max-width: 816px) {
  .header {
    .header__main {
      position: sticky;
      gap: 0;
    }

    .header__main {
      .header__main-btn {
        display: none;
      }
    }

    .header__main {
      .header__main-cart {
        .shopping-cart {
          width: 30px;
          height: 30px;
        }

        p {
          display: none;
        }
      }
    }
  }
}
@media (max-width: 320px) {
  .header {
    .header__main {
      position: sticky;
      gap: 0;
    }

    .header__main {
      .header__main-btn {
        display: none;
      }
    }

    .header__main {
      .header__main-cart {
        .shopping-cart {
          width: 24px;
          height: 24px;
        }

        p {
          display: none;
        }
      }
    }
  }
}
